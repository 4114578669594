import * as React from 'react'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import {
    Menu,
    MenuButton,
    MenuItems,
    MenuLink,
    MenuPopover,
    useMenuButtonContext,
} from '@reach/menu-button'
// import "@reach/menu-button/styles.css"
import Icon from '../assets/images/icon.svg'
import {AnimatePresence, motion } from 'framer-motion'

const LINKS = [
    {name: 'home', to: '/'},
    {name: 'how it works', to: '/how-it-works'},
    {name: 'contact', to: '/contact'},
]

// const MOBILE_LINKS = [{name: 'Home', to: '/'}, ...LINKS]

export const Navbar = () => {
     return (
         <div className="px-5 sm:px-6 lg:px-12 w-full pt-5 pb-5 absolute md:relative">
            <nav className="flex items-center justify-between max-w-8xl shrink">
                <Link to="/" className="underlined block whitespace-nowrap text-4xl font-medium focus:outline-none transition">
                    <img src={Icon} alt="icon.svg" width="250px" className="w-48"/>
                </Link>
                <ul className="hidden lg:flex">
                    {LINKS.map(link => {
                        return <NavLink to={link.to} key={link.to}>{ link.name }</NavLink>
                    })}
                </ul>
                <div className="lg:hidden flex items-center justify-center">
                    <div className="block lg:hidden">
                        <MobileMenu />
                    </div>
                </div>
            </nav>
         </div>
     )
}

const NavLink = ({to, ...rest}) => {
    const location = useLocation()
    let isSelected = location.pathname === to || location.pathname.startsWith(`${to}/`)
    return (
        <li className="px-5 py-2">
            <Link 
                className={clsx(
                    'relative text-xl block whitespace-nowrap text-lg font-medium focus:outline-none',
                    {
                    // 'border-b-2 border-black': isSelected,
                    'font-black': isSelected,
                    },
                )}
                to={to}
                {...rest}
            />
        </li>
    )
}

// const CustomNavLink = ({to, children, ...rest}) => {
//     const location = useLocation()
//     let isSelected = location.pathname === to || location.pathname.startsWith(`${to}/`)
//     // const handleFocus = (e) => {
//     //     const noStyle = document.getElementById('home')
//     //     console.log(noStyle)
//     //     if (e?.target?.contains(noStyle)) {
//     //         noStyle.after('')
//     //     }
//     // }
//     // React.useEffect(function(){
//     //     document.addEventListener('mouseover', handleFocus, true)
//     //     return () => document.removeEventListener('mouseover', handleFocus, true)
//     // })
//     return (
//             <Link 
//                 className={clsx(
//                     'relative no-style text-xl block whitespace-nowrap text-lg font-medium focus:outline-none',
//                     {
//                     // 'border-b-2 border-black': isSelected,
//                     'font-black': isSelected,
//                     },
//                 )}
//                 to={to}
//                 id={to}
//                 {...rest}
//             >
//                 <span className={clsx({
//                     "border-b-2 w-full": isSelected
//                 })}>
//                     {children}
//                 </span>
//             </Link>
//     )
// }


const topVariants = {
    open: {rotate: 45, y: 7},
    closed: {rotate: 0, y: 0},
  }
  
  const centerVariants = {
    open: {opacity: 0},
    closed: {opacity: 1},
  }
  
  const bottomVariants = {
    open: {rotate: -45, y: -5},
    closed: {rotate: 0, y: 0},
  }

  function MobileMenu() {
    return (
      <Menu>
        {({isExpanded}) => {
          const state = isExpanded ? 'open' : 'closed'
          return (
            <>
              <MenuButton>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <motion.rect
                    animate={state}
                    variants={topVariants}
                    x="6"
                    y="9"
                    width="20"
                    height="2"
                    rx="1"
                    fill="#000"
                  />
                  <motion.rect
                    animate={state}
                    variants={centerVariants}
                    x="6"
                    y="15"
                    width="20"
                    height="2"
                    rx="1"
                    fill="#000"
                  />
                  <motion.rect
                    animate={state}
                    variants={bottomVariants}
                    x="6"
                    y="21"
                    width="20"
                    height="2"
                    rx="1"
                    fill="#000"
                  />
                </svg>
              </MenuButton>
  
              <MobileMenuList />
            </>
          )
        }}
      </Menu>
    )
  }

  function MobileMenuList() {
    const {isExpanded} = useMenuButtonContext()
    React.useEffect(() => {
      if (isExpanded) {
        // don't use overflow-hidden, as that toggles the scrollbar and causes layout shift
        // document.body.classList.add('fixed')
        document.body.classList.add('overflow-y-hidden')
        // alternatively, get bounding box of the menu, and set body height to that.
        // document.body.style.height = '40vh'
      } else {
        // document.body.classList.remove('fixed')
        document.body.classList.remove('overflow-y-hidden')
        // document.body.style.removeProperty('height')
      }
    }, [isExpanded])
  
    return (
      <AnimatePresence>
        {isExpanded ? (
          <MenuPopover
            position={r => ({
              top: `calc(${Number(r?.top) + Number(r?.height)}px + 0.25rem)`, // 2.25 rem = py-9 from navbar
              bottom: 0,
              right: 0,
            })}
            style={{display: 'flex', flexDirection: 'column', alignItems: "flex-start", height: "202px"}}
            className="w-56 shadow-3xl rounded-l-lg"
          >
            <motion.div
              initial={{y: -50, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -50, opacity: 0}}
              transition={{duration: 0.15, ease: 'linear'}}
              style={{ zIndex: '4000000', display: 'flex', flexDirection: 'column'}}
              className="bg-white z-50 flex flex-col pb-12 border-lightgray overflow-y-scroll w-56 rounded-l-lg"
            >
              <MenuItems className="p-0 bg-white border-none flex flex-col">
                {LINKS.map(link => (
                  <MenuLink
                    className={clsx("hover:bg-primary focus:bg-primary hover:text-white px-6 py-5 w-full text-xl", {
                    })}
                    key={link.to}
                    as={Link}
                    to={link.to}
                  >
                    {link.name}
                  </MenuLink>
                ))}
              </MenuItems>
            </motion.div>
          </MenuPopover>
        ) : null}
      </AnimatePresence>
    )
  }
import clsx from "clsx"


export const Input = (props) => {
    const className = clsx("h-12 rounded-lg text-xl w-72 sm:w-80 border-secondary border-2 py-2 px-2 outline-none", props.className)
    if (props.type === "textarea") {
        return (
            <textarea {...props} className={className.replace("h-12", "h-52")}/>
        )
    }

    return (
        <input {...props} className={className}/>
    )
}
import { Heading1, Heading5 } from "./utils";
import Brain from "../assets/images/brain.svg"
import Body from "../assets/images/body.svg"
import Mood from "../assets/images/mood.svg"


export const Solutions = () => {
    return (
        <section className="w-full sm:px-12 md:px-28 xl:px-28 2xl:px-56 mt-28 sm:mt-44">
            <Heading1 font="Poppins">What is Wellbeing+ solution</Heading1>
            <ul id="solution-card" className="grid sm:flex snap-mandatory sm:overflow-x-auto scroll-p-1 scroll-smooth snap-x">
                <SolutionCard icon={ Brain } alt="brain">
                    <Heading5 color="secondary">
                        Promotes mindful self-awareness.
                    </Heading5>
                    <p className="text-left">
                        Mindfulness is simply being aware of how we’re feeling and what we’re thinking. Examples of mindfulness techniques can be found in meditation and cognitive behavioural therapy (CBT)
                        — mindfulness is so effective that it is proven to lower stress levels and alleviate depressive symptoms over time by just self-regulation [3][4].
                        <br />
                        <br />
                        Wellbeing+ has features designed to monitor behavioural patterns, moods, and thoughts, making it easier to identify, address, and fix problem areas.
                    </p>
                </SolutionCard>
                <SolutionCard icon={ Body } alt="body">
                    <Heading5 color="secondary">
                        Designed to emphasize mental and physical wellness.
                    </Heading5>
                    <p className="text-left">
                        Our physical health is just as important as our mental health: the two are linked by something called the mind-body connection [5]. 
                        Wellbeing+ offers wearables pairing to manage physical wellness alongside the mental and emotional; our smart recommendations make it easy to set and stay on top of goals. 
                        Research has shown a positive outlook and mood improves physical health while promoting healthy lifestyle choices, like regular exercise and good sleep hygiene, 
                        creating a positive behavioural cycle based on consistently practiced habits [6].
                    </p>
                </SolutionCard>
                <SolutionCard icon={ Mood } alt="mood">
                    <Heading5 color="secondary">
                        Based on clinical research and best practices.
                    </Heading5>
                        <p className="text-left">
                            Our features allow users to gain a deeper understanding of their wellbeing using behaviour and mood tracking, physical health metrics, no-stress goal setting, follow-through reminders, 
                            and smart recommendations that make checking-in and staying on track pressure-free.
                            <br />
                            We did our homework and designed wellbeing+ to prioritize helping you first, 
                            whether you are a healthcare stakeholder or someone who just wants help; our data-driven analytics and data visualization capabilities helps you gain actionable wellbeing insight and take the necessary next steps.
                        </p>
                </SolutionCard>
            </ul>
        </section>
    )
}

const SolutionCard = ({ icon, title, children, alt }) => {
    return (
      <li className="sm:w-full sm:mx-5 my-5 flex justify-center">
        <div className="rounded-lg shadow pt-5 pb-16 px-5 mb-5 w-80 sm:w-96 flex flex-col items-center" style={{minHeight: '512px'}}>
            <img src={ icon } alt={ alt } />
            { title }

            { children }
        </div>
      </li>
    )
}
import { HomeHeroMessage, Header } from "../components/Header"
import { MiniCard, Section2 } from "../components/Section"
import HowHiro from "../assets/images/How-Hero.svg"
import { AppLink, ChronicCondition, Heading1, Heading3, Heading5, TitleWithImage } from "../components/utils"
import BodyImg from "../assets/images/body.svg" 
import Dashboard from "../assets/images/Dashboard.svg"
import Condition from "../assets/images/employee_condition.svg"
import Improvement from "../assets/images/improvement_rate.svg"
import WellbeingFactor from "../assets/images/wellbeing_factors.svg"
import Health from "../assets/images/health.svg"
import MeTime from "../assets/images/me-time.svg"
import Prioritizing from "../assets/images/prioritizing.svg"
import WorkingOut from "../assets/images/working-out.svg"
import Trophy from "../assets/images/Trophy.svg"
import Woman from "../assets/images/woman.svg"
import Mood from "../assets/images/mood.svg"
import MultipleMood from "../assets/images/multiple-moods.svg"
import Brain from "../assets/images/brain.svg"
import Mobile1 from "../assets/images/mobile1.svg"
import Mobile2 from "../assets/images/mobile2.svg"
import Mobile3 from "../assets/images/mobile3.svg"
import Mobile4 from "../assets/images/mobile4.svg"
import Arrow from "../assets/images/arrow.svg"
import User from "../assets/images/men_on_board.svg"


const METRICS_IMAGES = {
  "Fitness": WorkingOut,
  "Health": Health,
  "Getting organized": Prioritizing,
  "Me-time": MeTime,
}
export const MetricSlide = () => {
  return (
    <div className="flex flex-left xl:w-full lg:mx-0">
      <ul id="card-list" className="flex snap-x snap-mandatory overflow-x-auto scroll-p-1 scroll-smooth gap-4 my-12 py-5 px-2 xl:grid grid-cols-4">
        {
          Object.keys(METRICS_IMAGES).map(key => (
            <MiniCard key={key} title={key}><img src={METRICS_IMAGES[key]} alt={key} width="150px"/></MiniCard>
          ))
        }
      </ul>
    </div>
  )
}

export const HowItWorks = () => {
    return (
        <>
            <Header
                hiroImagePosition="left"
                hiroImageUrl={HowHiro}
                hiroImageAlt="med and patient"
                messagePosition="right"
            >
                <HomeHeroMessage
                    hasButton
                    to="/"
                    btnMessage="Download APP"
                    msg
                >
                    <div className="flex flex-col self-end">
                    <p className="max-w-xl font-Poppins font-500 text-2xl text-left">
                        Three out of five people live with chronic diseases in North America
                    </p>
                    <p className="text-primary text-2xl sm:text-4xl font-Poppins mt-6 mb-12">
                        And we are here to help!
                    </p>
                    </div>
                </HomeHeroMessage>
            </Header>
            <Section2 itemPosition="left" className="px-2">
                <Heading1 mgBot={10} font="Poppins" textPos="left">Corporate wellbeing+ for Businesses</Heading1>
                <p className="text-xl text-left px-4 sm:px-12 md:px-0 xl:w-3/4 2xl:w-1/2">Corporate wellbeing + helps our partnered stakeholders by providing community health insights to guide policy changes.</p>
                <div className="text-left sm:pl-12 md:pl-0 w-full">
                    <Heading3 color="secondary" mgBot="16" textPos="center"> Corporate wellbeing+ Features</Heading3>
                </div>
                <div className="w-full">
                    <img src={Dashboard} alt="dashboard" className="w-full mb-5 hidden lg:block"/>
                    <div className="flex w-full sm:justify-center md:justify-between 2xl:justify-between items-center flex-col md:flex-row">
                        <img src={Condition} alt="" className="w-96 md:w-80 2xl:w-96"/>
                        <img src={Improvement} alt="" className="w-96 md:w-80 2xl:w-96"/>
                        <img src={WellbeingFactor} alt="" className="sm:hidden xl:w-80 xl:block 2xl w-96"/>
                    </div>
                </div>
                <div className="w-full flex justify-center mt-10">
                    <AppLink to="/" msg>Request a demo</AppLink>
                </div>
            </Section2>
            <Section2 itemPosition="left" className="px-8">
                <Heading1 font="Poppins" textPos="left">Wellbeing+ for Individuals</Heading1>
                <p className="text-xl text-left sm:px-6 sm:px-0 mb-8">
                  Use wellbeing + to track your daily activities, check your moods and health, get personalized health recommendations and risks.
                </p>
                <div className="flex flex-col w-full justify-center xl:w-3/5 items-start mt-80 sm:mt-0">
                    <TitleWithImage
                        title="Setting up Goals"
                        imageAlt="body"
                        imageHeihgt="50px"
                        imageUrl={BodyImg}
                        titlePos="start"
                    />
                    <p className="text-secondary text-left text-xl pl-5">Set up your daily goals and challenge yourself.</p>
                </div>
                <div className="w-full">
                  <MetricSlide />
                  <img src={Trophy} alt="trophy" className="absolute right-0 top-28 sm:top-48 sm:opacity-10 xl:block xl:opacity-100"/>
                </div>
                <div className="w-full flex flex-col xl:flex-row mt-10 sm:mt-28">
                        <img src={Woman} alt="woman" className="hidden xl:flex absolute left-0 bottom-54"/>
                        <div className="flex flex-col xl:w-3/5 items-end">
                            <TitleWithImage
                                title="Tracking Daily Mood"
                                imageAlt="mood"
                                imageHeihgt="40px"
                                imageUrl={Mood}
                            />
                            <p className="flex self-center text-center sm:text-left text-xl xl:pl-12">Inform us about your daily mood and let us <br/> take care of your wellbeing</p>
                        </div>
                        <div className="flex -flex-col items-end justify-center mt-12 xl:mt-0">
                            <img src={MultipleMood} alt="multiple moods" height="4rem"/>
                        </div>
                </div>
                <div className="w-full xl:px-28 mt-24 mb-36 xl:my-36 flex justify-center items-center flex-col">
                    <TitleWithImage
                        title="Health Checking in"
                        imageAlt="brain"
                        imageHeight="50px"
                        imageUrl={Brain}
                    />
                    <p className="text-center sm:text-left sm:mx-16 xl:mx-0 text-xl mt-5">Wellbeing + allows you to get personalized health recommendations and risks using your health check-in information.</p>
                    <ChronicCondition>
                        <Heading5 color="black" mgBot="0">Chronic condition</Heading5>
                        <p className="px-5 xl:px-0 text-xl text-center mt-5 xl:mt-0">Get personalized health recommendations based on your existing condition</p>
                        <AppLink to="/">Download App to get help</AppLink>
                    </ChronicCondition>
                    <div className="w-full sm:px-16 xl:px-0 flex flex-col lg:flex-row items-center justify-between">
                        <div className="w-full lg:w-3/12">
                            <p className="text-left text-xl leading-7">Wellbeing + use your health check-in information to help you address health problems before they arise.
                            <br/>
                            <br/>
                            Wellbeing + does not intend to replace doctors  
                            </p>
                        </div>
                        <div className="flex flex-col md:flex-row mt-8">
                            <img src={Mobile1} alt="mobile1" className="lg:w-32 w-72"/>
                            <img src={Mobile2} alt="mobile2" className="lg:w-32 w-72"/>
                            <img src={Mobile3} alt="mobile3" className="lg:w-32 w-72 md:block hidden"/>
                            <img src={Mobile4} alt="mobile4" className="lg:w-32 w-72 md:block hidden"/>
                        </div>
                    </div>
                    <div className="flex w-full flex-col sm:px-16 xl:px-0 xl:flex-row justify-between mt-20">
                        <div className="flex flex-col items-end self-start">

                            <TitleWithImage
                                title="Booking an appointment"
                                imageUrl={Arrow}
                                titlePos="start"
                                />
                            <p className="text-xl text-left max-w-xl mt-5">We work with licensed therapists and certified coaches to make sure your health concerns are cleared.</p>
                        </div>
                        <div className="flex items-end pt-12">
                            <img src={User} alt="dashboard" className="w-64"/>
                        </div>
                    </div>
                </div>
            </Section2>
        </>
    )
}

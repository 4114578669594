import * as React from 'react'
import { VisuallyHidden } from '@reach/visually-hidden'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import "@reach/dialog/styles.css"


const ModalContext = React.createContext()

const Modal = (props) => {
    const [isOpen, setIsOpen] = React.useState(false)
    return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

const useModalContext = () => {
    const context = React.useContext(ModalContext)
    if (!context) {
        throw Error('ModalContext should be used inside Modal Provider.')
    }
    return context
}

const ModalButtonClose = ({children}) => {
    const [, setIsOpen] = React.useContext(ModalContext)
    return React.Children.map(children, child => {
        console.log(child)
        if (child.type.displayName === 'button') {
            return React.cloneElement(child, {onClick: () => {
                setIsOpen(false)
            }})
        }
    })
}

const ModalButtonOpen = ({children: child}) => {
    const [, setIsOpen] = React.useContext(ModalContext)
    return React.cloneElement(child, {onClick: () => setIsOpen(true)})
}

const ModalContentBase = ({children, ariaLabel, ...props}) => {
    const [isOpen, setIsOpen] = React.useContext(ModalContext)
    return (
        <DialogOverlay 
            aria-label={ariaLabel} style={{display: 'grid',
            placeItems: 'center'}}
            isOpen={isOpen}
            onDismiss={() => setIsOpen(false)} {...props}>
            <DialogContent aria-label="Modal content" style={{maxWidth: "450px"}} className="relative shadow-md rounded-lg">{children}</DialogContent>
        </DialogOverlay>
        )
}

const ModalContents = ({children, ...props}) => {
    return (
        <ModalContentBase>
            <div>
                <ModalButtonClose>
                    <CircleButton>
                        <VisuallyHidden>Close</VisuallyHidden>
                        <span aria-hidden className="text-4xl">&times;</span>
                    </CircleButton>
                </ModalButtonClose>
            </div>
            {children}
        </ModalContentBase>
    )
}

const CircleButton = ({ children }) => {
    return (
        <button 
            className="absolute right-1 top-0 w-12 h-12 rounded-full text-lg outline-none flex items-center justify-center cursor-pointer">
            {children}
        </button>
    )
}

export {
    Modal,
    ModalButtonClose,
    ModalButtonOpen,
    ModalContentBase,
    ModalContents,
    useModalContext
}
import * as React from 'react'
import { Route } from 'react-router'
import clx from 'clsx'
import { NavLink } from 'react-router-dom'
import Icon from '../assets/images/icon.svg'
import { AppLink } from './utils'
import PhoneHero from '../assets/images/phone_hero.png'
import HowHero from '../assets/images/herohowmobile.svg'

export const Navigation = () => {
    return (
        <div className="flex justify-between pt-4 px-14">
            <NavIcon />
            <NavList />
        </div>
    )
}

const NavIcon = () => {
    return <NavLink to="/"><img src={Icon} alt="icon.svg" width="250px" className="z-index-5"/></NavLink>
}

const NavItem = ({ name, to }) => {
    return <NavLink exact to={to} activeClassName="font-black" className="flex flex-shrink-0 text-xl">{ name }</NavLink>
}

const NavList = () => {
    return (
        <div className="w-72 lg:flex justify-between pt-1 z-10 hidden">
            <NavItem name="home" to="/" />
            <NavItem name="how it works" to="/how-it-works" />
            <NavItem name="contact" to="/contact" />
        </div>
    )
}

export const Header = ({ hiroImagePosition, hiroImageAlt, children, hiroImageUrl, messagePosition="left", page }) => {
    return (
        <header className="w-full sm:grid sm:place-items-center md:block">
            <img alt={hiroImageAlt} src={hiroImageUrl} className={ clx("hidden md:block absolute top-0", {
                "right-0": hiroImagePosition === "right",
                "left-0": hiroImagePosition === "left",
                }) } style={{zIndex: -1}}/>
            <Route path="/" exact>
                <img src={ PhoneHero } id="hero-phone" alt="wellbeing phone" className="top-0 md:hidden w-full" style={{zIndex: -1}}/>
            </Route>
            <Route path="/how-it-works" exact>
                <img src={ HowHero } id="hero-phone" alt="wellbeing phone" className="top-0 md:hidden w-full" style={{zIndex: -1}}/>
            </Route>
            <div id="text-after-hero" className={ clx("my-28 sm:mt-40 sm:p-0 md:my-28 md:mt-96 lg:mt-24 md:pl-12 lg:pl-20 2xl:pl-44 lg:mt-96 xl:mt-40 flex justify-start flex-col", {
                "items-start": messagePosition === "left",
                "items-end": messagePosition === "right",
            }) }
            >
                { children }
            </div>
        </header>
    )
}

export const HomeHeroMessage = ({ hasButton, to, imageUrl, btnMessage, hasImageAsTitle, children, messagePosition, msg }) => {
    return (
        <div className="text-left px-4 sm:px-12 md:px-8 sm:pr-44">
            { 
                hasImageAsTitle ? (
                    <div className="flex-shrink-0">
                        <img src={ imageUrl } alt="wellbeing title" className="sm:flex sm:items-center"/>
                    </div> 
                    ) : null
            }
            { children }
            
            { hasButton ? <AppLink to={to} msg={msg}>{btnMessage}</AppLink> : null }
        </div>
    )
}


import { Link } from "react-router-dom"
import { Route } from "react-router-dom"
import IBM from "../assets/images/IBM.svg"
import Meds from "../assets/images/med_and_nurse.svg"


export const Footer = () => {
    return (
        <footer className="w-full px-8 py-4 bg-secondary block justify-between sm:flex sm:flex-raw">
            <div className="pt-5 text-xl self-center my-8 sm:my-0 flex flex-col sm:flex-raw sm:self-start">
                <p className="text-wellbeing  text-center sm:text-left text-3xl sm:text-2xl font-Rokkitt">Wellbeing+</p>
                <p className=" text-center sm:text-left text-white mb-5 sm:mb-3">by Nytia Labs</p>
                <ul className="leading-7 p-0">
                    <li className="list-none text-white text-center sm:text-left">
                        <Link to="how-it-works">how it works</Link>
                    </li>
                    <li className="list-none text-white text-center sm:text-left">
                        <Link to="data-privacy-policy">data & privacy policy</Link>
                    </li>
                    <li className="list-none text-white text-center sm:text-left">
                        <Link to="contact">contact us</Link>
                    </li>
                </ul>
            </div>
            <div className="relative self-center sm:self-end">
                <Route path="/how-it-works" exact>
                    <img src={Meds} alt="" className="md:block absolute -top-32 right-0 hidden"/>
                </Route>
                <img src={ IBM } alt="ibm partenaire" />
            </div>
        </footer>
    )
}
import * as React from "react"
import { AppButton } from "../components/utils"
import ContactImage from "../assets/images/contact.svg"
import { Input } from "../components/Form"
import { ModalContents, useModalContext } from "../components/modal"
import Mail from "../assets/images/mail.svg"
import { v4 as uuid } from "uuid";
import { initializeApp } from "firebase/app";
import { doc, getFirestore, setDoc } from "firebase/firestore"
const firebaseConfig = {
  apiKey: "AIzaSyAI25EKt6eed1FJOy9JzKnjzyEakd99p04",
  authDomain: "nytia-email-collections.firebaseapp.com",
  projectId: "nytia-email-collections",
  storageBucket: "nytia-email-collections.appspot.com",
  messagingSenderId: "158815338880",
  appId: "1:158815338880:web:72fd8694e873e6656d4165",
  measurementId: "G-4GQ3EGBDMG",
};

initializeApp(firebaseConfig);
const firestore = getFirestore();

export const Contact = () => {
  const [formValue, setFormValue] = React.useState({username: "", email: "", message: ""})
  const [, setIsOpen] = useModalContext()
  const [disabledBtn, setDisableBtn] = React.useState(false)
  const [isError, setError] = React.useState(false)
  
    const handleSubmit = async (e) => {
        setDisableBtn(true)
        e.preventDefault();
        const clientInfo = doc(firestore, `client/${(uuid())}`);
        setDoc(clientInfo, formValue)
          .then(_ => {
            const form = document.querySelector('form');
            form.reset();
            setDisableBtn(false);
            setIsOpen(true);
          })
          .catch(e => {
            console.log('error', e);
            setError(true);
            setIsOpen(true);
            setDisableBtn(false);
          });
    }

    return (
        <div className="w-full overflow-hidden flex items-center justify-center xl:flex-row  flex-col">
            <div className="w-96 flex flex-col mt-32 xl:my-32 xl:mr-12">
                <h1 className="text-2xl px-5 md:px-0 w-full text-purple text-primary sm:text-5xl mb-8 md:mt-0 text-center xl:text-left">Contact us</h1>
                <p className="text-xl px-12 sm:px-0 text-center sm:text-left">Are you a licensed therapist, a certified coach or interested to know more about what we do, drop us a message !</p>
                <img src={ContactImage} alt="contact" className="mt-16"/>
            </div>
            <div className="flex items-end my-32 xl:ml-12">
                <p></p>
                <form onChange={(e) => {
                    const form =  e.currentTarget
                    setFormValue({ username: form.name.value, email: form.email.value, message: form.message.value })
                }} onSubmit={(e) => handleSubmit(e)}>
                    <fieldset className="mb-4">
                        <Input required type="text" id="name" placeholder="Name:"/>
                    </fieldset>
                    <fieldset className="mb-4">
                        <Input required type="email" id="email" placeholder="Email:"/>
                    </fieldset>
                    <fieldset className="mb-4">
                        <Input required type="textarea" id="message" placeholder="Message:" />
                    </fieldset>
                    <fieldset className="flex w-full justify-end w-72 sm:w-80">
                        <AppButton type="submit" disabled={disabledBtn}>contact us</AppButton>
                    </fieldset>
                </form>
            </div>
            <ModalContents>
                <div className="flex flex-col items-center justify-center h-72 sm:h-80">
                    <img src={Mail} alt="mail" />
                    {
                        !isError ? (
                            <>
                                <p className="font-bold font-sans  my-8">Thank you!</p>
                                <p className="text-center">We have received your message, we will get back to you as soon as possible!</p>
                            </>
                            ):
                            (
                            <>
                                <p className="font-bold font-sans text-red my-8">Oups...</p>
                                <p className="text-center text-red">Something went wrong. Please, reload the page and try again later.</p>
                            </>
                        )
                    }
                </div>
            </ModalContents>
        </div>
    )
}

import clsx from 'clsx'

export const Section = ({ children, className, itemPosition = "center" }) => {
    return (
        <section className={ clsx("w-full lg:px-40 xl:px-16 xl:px-40 mt-16 mb-16 sm:mb-0 flex flex-col relative", className,
            { 
                "place-items-center": itemPosition === "center",
                "items-start": itemPosition === "left"
             })}>
            { children }
        </section>
    )
}

export const Section2 = ({ children, itemPosition = "center", className }) => {
    return (
        <section className={ clsx("w-full lg:px-40 xl:px-16 xl:px-40 mt-16 mb-16 sm:mb-0 md:px-16 flex flex-col relative", className, 
            { 
                "place-items-center": itemPosition === "center",
                "items-start": itemPosition === "left"
             })}>
            { children }
        </section>
    )
}

export const MiniCard = ({ children, title, height, width, allowCustom, className, last }) => {
    if (!allowCustom) {
      return (
        <li className='w-full'>
          <div className={ clsx("sm:h-52 sm:w-52 md:h-64 md:w-64 w-44 h-44 lg:w-48 lg:h-52 xl:w-48 xl:h-48 rounded-lg shadow flex items-cener justify-center py-3 relative my-2 mx-2 ", {
              'hidden sm:flex': last})}>
                { children }
              <span 
                  style={{ position: "absolute", bottom: "10px", transform: "translateX(-50%)", left: "50%"}}
                  className="font-bold text-xs md:text-md">
                  { title }
              </span>
          </div>
        </li>
      )
    }
    return (
        <div className={`sm:h-${height ?? 64} sm:w-${width ?? 60} w-32 h-52 rounded-lg shadow flex items-cener justify-center py-3 relative my-2 mx-2`}>
            { children }
            <span 
                style={{ position: "absolute", bottom: "1px", transform: "translateX(-50%)", left: "50%"}}
                className="font-bold text-md">{ title }</span>
        </div>
    )
}
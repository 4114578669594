import { Header, HomeHeroMessage } from "../components/Header"
import { MiniCard } from "../components/Section"
import { Solutions } from "../components/Solution"
import { References } from "../components/References"
import { Slogan } from "../components/Slogan"

import WellbeingImgTitle from '../assets/images/wellbeing.svg'
import HomeHeroImg from '../assets/images/hero_image.svg'
import Biometric from '../assets/images/biometric.svg'
import Nutrition from '../assets/images/nutrition.svg'
import Sleep from '../assets/images/sleep.svg'
import Movement from '../assets/images/movement.svg'
import HeartBeat from '../assets/images/heart-beat.svg'
// import { Heading1 } from '../components/utils'


const METRICS_IMAGES = {
  "Biometric data": Biometric,
  "Nutrition data": Nutrition,
  "Sleep data": Sleep,
  "Movement data": Movement,
}
export const MetricSlide = () => {
  return (
    <div className="flex justify-center xl:w-full mx-10 md:mx-28 lg:mx-0">
      <ul id="card-list" className="flex snap-x snap-mandatory overflow-x-auto scroll-p-1 scroll-smooth gap-4 my-12 py-5 px-2 xl:grid grid-cols-4">
        {
          Object.keys(METRICS_IMAGES).map(key => (
            <MiniCard key={key} title={key}><img src={METRICS_IMAGES[key]} alt={key} width="150px"/></MiniCard>
          ))
        }
      </ul>
    </div>
  )
}

export const Home = () => {
    return (
      <>
        <Header
            hiroImagePosition="right"
            hiroImageAlt="heart"
            hiroImageUrl={HomeHeroImg}
            page="home"
        >
          <HomeHeroMessage
              hasButton
              hasImageAsTitle
              to="/how-it-works"
              imageUrl={WellbeingImgTitle}
              btnMessage="Learn More">
              <p className="pt-7 pb-12 text-left text-xl font-thin max-w-lg">
                <span className="glacial text-primary">Figuring out why we feel off is complicated.</span>
                <br/>
                Nytia Labs is on a mission of building a data-driven preventive care platform
                that helps users prevent and manage efficiently the development of chronic
                conditions and partnered stakeholders by providing community health insights
                to guide policy changes
              </p>
          </HomeHeroMessage>
        </Header>
        <div className="relative">
          {/* <Heading1 textPos="center" mgTop={5} font="Poppins">We help people with data</Heading1> */}
          <h1 className="text-2xl px-5 md:px-0 w-full text-Poppins text-purple sm:text-5xl text-primary mb-16 mt-16 md:mt-5 md:text-center">We help people with data</h1>
          <p className="lg:mb-16 text-left px-12 md:px-28 2xl:px-56 lg:max-w-8xl text-xl font-glacial">
              Our platform is powered by hundreds of millions of clinical and scientific data. 
              We empower people with aggregated data from their daily goals, health check-in data such as biometrics data, nutrition data, 
              sleep data and movement data. <span className="text-primary font-bold">That's why your data privacy is our top concern.</span>
          </p>
          <MetricSlide/>
          <div className="flex justify-end  flex-col sm:flex-raw sm:w-full xl:w-full 2xl:w-4/5 sm:mt-16lg:mt-10 xl:mt-16  mb-16">
              <div className="lg:w-2/3 w-full lg:w-full xl:w-3/5 xl:self-end">
                  <p className="px-12 md:px-28 text-center text-justify font-thin text-xl">
                      {/* <span className="text-primary font-bold">Depression </span>  */}
                      {/* is a public health crisis, where 75% of issues appear before the age of 25: in particular, 
                      students are highly susceptible to developing long-lasting mental health issues with increasing rates of depression, 
                      anxiety, and suicide attempts being reported on campus. */}
                      <span className="text-primary font-bold">Wellbeing+</span> leverages an evidence-based model to change your quantified 
                      wellbeing indicators into a personalized and very meaningful health recommendations and risks.
                      <br />
                      <br />
                      {/* Properly addressing mental health issues requires an approach that accounts for the context in which these issues arise and taking action at the collective level,
                      whether that means on campus, in the workplace, or at the government level. */}
                      <span className="text-primary font-bold">Wellbeing+</span> makes sure you stay ahead of your potential health issues before they arise, 
                      so you take appropriate actions to prevent them.
                  </p>
                  <img src={HeartBeat} alt="heart and MD" className="block md:absolute lg:absolute lg:-bottom-80 xl:-bottom-10 w-full mt-16 md:mt-0 sm:w-1/2 lg:w-1/3 lg:mt-0 -botom-80 left-0 lg:mt-16 md:opacity-10 xl:opacity-100"/>
              </div>
          </div>
        </div>
        <Solutions />
        <References />
        <Slogan />
      </>
    )
}
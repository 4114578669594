// import { Heading1 } from "./utils"

export const Slogan = () => {
    // mb-12 modifier title
    // w-3/4 title
    return (
        <section className=" h-44 flex justify-center items-center bg-slogan font-Poppins">
            <h1 className="text-2xl px-5 md:px-0 w-full text-secondary sm:text-3xl md:text-4xl lg:text-5xl text-center"> 
                “We have people wellbeing at heart”
            </h1>
        </section>
    )
}
import { useHistory } from "react-router-dom"
import clsx from 'clsx'


export const AppLink = ({ children, to, msg }) => {
    const history = useHistory()
    return (
        <div className='text-left'>
            <button  onClick={() => history.push(to)} className="bg-primary h-12 py-0 px-4 sm:px-12 sm:px-4 flex justify-center items-center text-white rounded-md my-3 sm:mt-3 sm:mb-3 sm:my-0">
                <span className="font-Poppins">{ children }</span>
            </button>
            {msg && <p className="text-red text-left text-lg">Available soon</p>}
        </div>
    )
}

export const AppButton = ({ children, disabled, type="button" }) => {
    return (
        <button disabled={disabled} className={clsx('bg-primary h-12 py-0 px-4 flex justify-center items-center text-white rounded-md', {
            'opacity-20': disabled,
            'opacity-100': !disabled
        })} type={type}>
            <span className="font-Poppins">{ children }</span>
        </button>
    )
}

export const Heading1 = ({ children, mgTop, mgBot, color, font, textPos="center", size="", className }) => {
  return <h1 className={clsx('text-2xl px-5 md:px-0 w-full text-purple sm:text-5xl', {
    'text-secondary': color === 'secondary',
    'text-primary': color !== 'secondary',
    'mb-10': mgBot === 10,
    'mb-0': mgBot === 0,
    'mb-16': mgBot !== 0,
    'md:mt-16': !mgTop,
    'md:mt-5': mgTop === 5,
    'md:mt-0': mgTop === 0,
    'md:text-center': textPos === 'center',
    'md:text-left': textPos === 'left'
  }, className)}>{ children }</h1>
}

// export const Heading1 = ({ children, mgTop, mgBot, color, font, textPos="center", size="", className }) => {

//     return <h1 className={`text-2xl px-5 md:px-0 w-full text-purple text-${color ?? 'primary'} font-${font} sm:text-5xl mb-${mgBot ?? 16} md:mt-${mgTop ?? 16} md:text-${textPos} ${className ?? ""}`}>{ children }</h1>
// }

export const Heading2 = ({ children, mgTop, mgBot, color }) => {
    return <h2 className={`text-purple text-${color ?? 'primary'} text-4xl mb-${mgBot ?? 14} mt-${mgTop ?? 14}`}>{ children }</h2>
}

export const Heading3 = ({ children, mgTop, mgBot, color, textPos }) => {
    return <h3 className={`text-purple text-${color ?? 'primary'} w-full text-${textPos} sm:text-left text-xl sm:text-3xl mb-${mgBot ?? 12} mt-${mgTop ?? 12}`}>{ children }</h3>
}

export const Heading4 = ({ children, mgTop, mgBot, color }) => {
    return <h4 className={`text-purple text-${color ?? 'primary'} text-2xl mb-mb-${mgBot ?? 10} mt-${mgTop ?? 10}`}>{ children }</h4>
}

export const Heading5 = ({ children, mgTop, mgBot, color, ...rest }) => {
    return <h5 className={`text-purple text-${color ?? 'primary'} text-xl mb-${mgBot ?? 5} mt-5 sm:mt-0 font-bold`} {...rest}>{ children }</h5>
}

export const Heading6 = ({ children, mgTop, mgBot, color }) => {
    return <h6 className={`text-purple text-${color ?? 'primary'} text-lg mb-${mgBot ?? 3} mt-${mgTop ?? 3} font-bold`}>{ children }</h6>
}

export const TitleWithImage = ({ imageUrl, imageAlt, imageWidth, imageHeihgt, titlePos, title }) => {
    return (
        <div className={ clsx("flex justify-start items-center font-Poppins", {
            "self-center": !titlePos,
            "self-start": titlePos === 'start'
        }) }>
            <img src={imageUrl} alt={imageAlt} className="mr-3 pt-5 sm:pr-5" width={imageWidth ?? "auto"} height={imageHeihgt ?? "auto"}/>
            <Heading3 color="secondary" mgBot="5" textPos="left">{title}</Heading3>
        </div>
    )
}

export const ChronicCondition = ({ children }) => {
    return (
        <div className="w-full sm:w-4/5 xl:w-full bg-mood rounded-lg flex flex-col items-center justify-evenly h-80 xl:h-60 my-16 py-6">
            {children}
        </div>
    )
}
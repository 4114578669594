export const References = () => {
    return (
        <section className="w-full flex sm:items-center sm:justify-center">
            <div className="text-left px-12 md:px-28 2xl:px-56 my-12">
                <p className="text-reference">References:</p>
                <br />
                <small className="text-reference font-xs text-left">
                    [1] Mechanic, D & McAlpine, D. (2002). The Influence of Social Factors in Mental Health in Principles and Practice of Geriatric Psychiatry (2ED). Retrieved from https://doi-org/10.1002/0470846410.ch17.
                    <br />
                    [2] Council of Ontario Universities (2016) Ensuring a Whole-of-Community Approach to Mental Health. Retrieved from https://ontariosuniversities.ca/issues-priorities/student-supports.
                    <br />
                    [3] Fitzpatrick KK, Darcy A, Vierhile M Delivering Cognitive Behavior Therapy to Young Adults With Symptoms of Depression and Anxiety Using a Fully Automated Conversational Agent (Woebot): A Randomized Controlled Trial JMIR Ment Health 2017;4(2):e19. DOI: 10.2196/mental.7785
                    <br />
                    [4] Jayewardene, W. P., Lohrmann, D. K., Erbe, R. G., & Torabi, M. R. (2016). Effects of preventive online mindfulness interventions on stress and mindfulness: A meta-analysis of randomized controlled trials. Preventive medicine reports, 5, 150–159. DOI: 10.1016/j.pmedr.2016.11.013
                    <br />
                    [5] Wolinsky, H. (2006). Mind-body research moves towards the mainstream. EMBO Reports, 7(4), 354–358. DOI: 10.1038/sj.embor.7400670.
                    <br />
                    [6] Calvo RA, Peters D. (2014) Positive computing: technology for wellbeing and human potential. MIT Press.
                </small>
            </div>
        </section>
    )
}
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
// import './App.css';
// import "@reach/menu-button/styles.css"
import './index.css'
import { Home } from './views/Home'
import { HowItWorks } from './views/HowItWorks'
import { Footer } from "./components/Footer"
import { Contact } from "./views/Contact"
import { Modal } from './components/modal';
import { Navbar } from './components/navbar';

function App() {
  return (
  <Router>
    <Modal>
    <div className="App">
      <Navbar />
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/contact" exact>
          <Contact />
        </Route>
        <Route path="/how-it-works" exact>
          <HowItWorks />
        </Route>
        <Redirect to="/" />
      </Switch>
      <Footer/>
    </div>
    </Modal>
  </Router>
  );
}

export default App;
